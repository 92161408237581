import { configureStore } from '@reduxjs/toolkit'

// auth
import { authReducer } from '@/store/auth/slice'
// halls
import { hallsReducer } from '@/store/halls/slice'
// menu
import { menuReducer } from '@/store/menu/slice'
// order
import { orderReducer } from '@/store/order/slice'
// phone
import { phoneReducer } from '@/store/phone/slice'
// sms
import { smsReducer } from '@/store/sms/slice'
// summary
import { summaryReducer } from '@/store/summary/slice'

const reducer = {
  auth: authReducer,
  halls: hallsReducer,
  menu: menuReducer,
  order: orderReducer,
  phone: phoneReducer,
  sms: smsReducer,
  summary: summaryReducer,
}

export const store = configureStore({
  reducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store
