import { lazy, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'

import { getIsUserLoggedIn } from '@/store/auth/selector'
import { parseLS } from '@/utils'

// lazy
const AuthenticationPage = lazy(
  () => import(/* webpackChunkName: "auth" */ '@/containers/Authentication')
)
const HallPickerPage = lazy(
  () => import(/* webpackChunkName: "hallpicker" */ '@/containers/HallPicker')
)
const DashboardPage = lazy(
  () => import(/* webpackChunkName: "dashboard" */ '@/containers/Dashboard')
)
const CashboxPage = lazy(() => import(/* webpackChunkName: "cashbox" */ '@/containers/Cashbox'))
const WorkersPage = lazy(() => import(/* webpackChunkName: "workers" */ '@/containers/Workers'))
const OrderPage = lazy(() => import(/* webpackChunkName: "workers" */ '@/containers/Order'))
const GamesReport = lazy(() => import('containers/GamesReport'))
const Attendance = lazy(() => import(/* webpackChunkName: "workers" */ '@/containers/Attendance'))
const ChangePhonePage = lazy(
  () => import(/* webpackChunkName: "change-phone" */ 'containers/ChangePhone')
)
const SmsMailingPage = lazy(
  () => import(/* webpackChunkName: "sms-mailing" */ '@/containers/SmsMailing')
)
const SummaryReportPage = lazy(() => import('@/containers/SummaryReport'))

// TODO: error boundry
const ErrorBoundary = () => {
  useEffect(() => {
    localStorage.setItem(`error`, `666`)
  }, [])
  return <div>Ошибка-рыбка!</div>
}

// pages
export const ROUTES: Record<string, string> = {
  root: '/',
  auth: '/auth/login',
  halls: '/halls',
  error: '/error',
  dummy: '/dummy',
  dashboard: '/dashboard',
  equipment: '/equipment',
  workers: '/workers',
  cashbox: '/cashbox',
  games: '/games-report',
  attendance: '/attendance',
  phone_change: '/phone/request',
  create_sms_mailings: '/sms/mailing',
}

export const CANARY_ROUTES: Record<string, string> = {
  summary: '/summary',
}

interface PrivateRouteProps {
  children: JSX.Element
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const user = useSelector(getIsUserLoggedIn)
  const userLS = parseLS('user')

  return user || userLS ? children : <Navigate to={ROUTES.auth} replace />
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={ROUTES.root} element={<Navigate to={ROUTES.auth} replace />} />
      <Route path={ROUTES.auth} element={<AuthenticationPage />} />
      <Route
        path={ROUTES.halls}
        element={
          <PrivateRoute>
            <HallPickerPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.error}
        element={
          <PrivateRoute>
            <ErrorBoundary />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.dashboard}
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.equipment}
        element={
          <PrivateRoute>
            <OrderPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.workers}
        element={
          <PrivateRoute>
            <WorkersPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.cashbox}
        element={
          <PrivateRoute>
            <CashboxPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.games}
        element={
          <PrivateRoute>
            <GamesReport />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.attendance}
        element={
          <PrivateRoute>
            <Attendance />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.phone_change}
        element={
          <PrivateRoute>
            <ChangePhonePage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.create_sms_mailings}
        element={
          <PrivateRoute>
            <SmsMailingPage />
          </PrivateRoute>
        }
      />
      <Route
        path={CANARY_ROUTES.summary}
        element={
          <PrivateRoute>
            <SummaryReportPage />
          </PrivateRoute>
        }
      />
    </>
  )
)
