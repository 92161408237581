import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SummaryState } from '@/store/summary/types'

const initialSummaryState: SummaryState = {
  hallId: 0,
  rowKey: '',
}

const summarySlice = createSlice({
  name: 'summary',
  initialState: initialSummaryState,
  reducers: {
    setHallId: (state, action: PayloadAction<number>) => {
      state.hallId = action.payload
    },
    setRowKey: (state, action: PayloadAction<string>) => {
      state.rowKey = action.payload
    },
  },
})

// actions
export const { setHallId, setRowKey } = summarySlice.actions

// reducer
export const { reducer: summaryReducer } = summarySlice
